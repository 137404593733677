/* eslint-disable no-underscore-dangle */
/* eslint-disable no-param-reassign */
import { useEffect, useReducer } from 'react';
import * as axios from 'axios';

// Utils
import { isNil } from 'lodash';
import { useAppContext } from '../store/AppContext';
import mockRequest from './mocker';
import useCache from '../hooks/useCache';
import useImpersonate from '../hooks/useImpersonate';
import getApiUrlByHostname from '../utils/getApiUrlByHostname';
import { getStorageItem, setStorageItem } from '../utils/storage';
import useLogout from '../hooks/useLogout';

const requestReducer = (state, action) => {
  switch (action.type) {
    case 'REQUEST_INIT':
      return {
        ...state,
        requestState: 'loading',
        payload: null,
        error: null,
      };
    case 'REQUEST_SUCCESS':
      return {
        ...state,
        requestState: 'completed',
        payload: action.payload,
        error: null,
      };
    case 'REQUEST_FAILURE':
      return {
        ...state,
        requestState: 'error',
        payload: null,
        error: action.payload,
      };
    default:
      throw new Error(action.payload);
  }
};

const Axios = axios.create({
  baseURL: getApiUrlByHostname(),
  headers: {
    Accept: 'application/json',
    'Content-Type': 'application/json',
  },
});

Axios.interceptors.request.use((config) => {
  const userToken = getStorageItem('token');
  const impersonateUser = getStorageItem('impersonateUser');

  if (impersonateUser?.token || userToken?.token) {
    config.headers.Authorization = `Bearer ${impersonateUser?.token || userToken?.token}`;
  }

  return config;
});

const useApi = () => {
  const [state, dispatch] = useReducer(requestReducer, {
    requestState: 'pending',
    payload: null,
    error: null,
  });

  const [{ apiCached }, stateDispatch] = useAppContext();
  const { handleCachedResults } = useCache();
  const { handleImpersonateLogout } = useImpersonate();
  const logout = useLogout();

  useEffect(() => {
    const interceptor = Axios.interceptors.response.use(
      (response) => response,
      async (err) => {
        const impersonateUser = getStorageItem('impersonateUser');
        const originalRequest = err.config;

        if (err.response?.status === 401) {
          if (!originalRequest._retry) {
            if (impersonateUser?.token) {
              handleImpersonateLogout();
            } else {
              originalRequest._retry = true;
              const userToken = getStorageItem('token');

              if (userToken?.refresh_token) {
                try {
                  const newUserAuth = await axios({
                    method: 'post',
                    url: `${getApiUrlByHostname()}/token/refresh`,
                    data: {
                      refresh_token: userToken.refresh_token,
                    },
                  });

                  if (newUserAuth.data) {
                    setStorageItem('token', {
                      token: newUserAuth?.data?.token,
                      refresh_token: newUserAuth?.data?.refresh_token,
                    });
                    originalRequest.headers.Authorization = `Bearer ${newUserAuth.data.token}`;

                    return Axios(originalRequest);
                  }
                } catch (error) {
                  logout();
                }
              } else if (window.location.pathname !== '/login') {
                logout();
              }
            }
          } else if (window.location.pathname !== '/login') {
            logout();
          }
        }

        return Promise.reject(err);
      },
    );
    return () => Axios.interceptors.response.eject(interceptor);
  }, []);

  const responseHandler = (res, cache) => {
    if (!isNil(res.data)) {
      dispatch({ type: 'REQUEST_SUCCESS', payload: mockRequest(res.data, res?.config?.url) });

      if (cache?.key && cache?.value && apiCached) {
        const cachedValue = {
          value: res,
          expirationDate: cache.duration ? Date.now() + cache.duration : null,
        };

        stateDispatch({
          type: 'SET_API_CACHE',
          payload: {
            ...apiCached,
            [cache.key]: apiCached[cache.key]
              ? { ...apiCached[cache.key], [cache.value]: cachedValue }
              : { [cache.value]: cachedValue },
          },
        });
      }
    }

    if (res.status === 204) dispatch({ type: 'REQUEST_SUCCESS' });

    return { ...res, data: mockRequest(res.data, res?.config?.url) };
  };

  const errorHandler = (resError) => {
    if (resError.response) {
      const status = resError.response ? resError.response.status : null;

      switch (status) {
        case 400:
          dispatch({
            type: 'REQUEST_FAILURE',
            payload: resError.response.data,
          });
          break;
        case 404:
          dispatch({
            type: 'REQUEST_FAILURE',
            payload: resError.response.data,
          });
          break;
        case 401:
          dispatch({
            type: 'REQUEST_FAILURE',
            payload: resError.response.data,
          });
          break;
        case 500: {
          dispatch({
            type: 'REQUEST_FAILURE',
            payload: resError.response.data,
          });
          break;
        }
        case 502: {
          dispatch({
            type: 'REQUEST_FAILURE',
            payload: resError.response.data,
          });
          break;
        }
        case 503: {
          dispatch({
            type: 'REQUEST_FAILURE',
            payload: resError.response.data,
          });
          break;
        }
        default:
          dispatch({
            type: 'REQUEST_FAILURE',
            payload: resError.response.data,
          });
          break;
      }
    } else {
      dispatch({
        type: 'REQUEST_FAILURE',
        payload: null,
      });
    }

    return resError;
  };

  const api = (requestConfig, cache) => {
    dispatch({ type: 'REQUEST_INIT' });

    return handleCachedResults(cache, dispatch).then((cacheResult) => {
      if (cache && cacheResult) return cacheResult;

      return Axios(requestConfig).then((res) => responseHandler(res, cache)).catch(errorHandler);
    });
  };

  return {
    requestState: state.requestState,
    payload: state.payload,
    error: state.error,
    api,
  };
};

export default useApi;
