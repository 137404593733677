import SCOPES from './scopes';

// Admin roles :
// - super admin (technical + pneumaclic)
// - admin (pneumaclic)
// - key account admin (key account director)
// - key account regional admin (key account regional director)

// Client roles :
// - visitor
// - customer
// - director (main user)
// - multi centers director

export const ROLES = {
  // admin
  ROLE_TECHNICAL_SUPER_ADMIN: 'ROLE_TECHNICAL_SUPER_ADMIN', // ZOL + Pneumaclic
  ROLE_SUPER_ADMIN: 'ROLE_SUPER_ADMIN', // Pneumaclic super admin
  ROLE_ADMIN: 'ROLE_ADMIN', // Pneumaclic admin
  ROLE_KEY_ACCOUNT_CLIENT_ADMIN: 'ROLE_KEY_ACCOUNT_CLIENT_ADMIN', // Key account admin
  ROLE_KEY_ACCOUNT_CLIENT_REGIONAL_ADMIN: 'ROLE_KEY_ACCOUNT_CLIENT_REGIONAL_ADMIN', // Key account regional admin

  // client
  ROLE_USER: 'ROLE_USER', // Viewer only, can't buy anything
  ROLE_CUSTOMER: 'ROLE_CUSTOMER', // Can place an order
  ROLE_MAIN_USER: 'ROLE_MAIN_USER', // Same as ROLE_DIRECTOR
  ROLE_DIRECTOR: 'ROLE_DIRECTOR', // Can do anything
  ROLE_MULTI_CENTER_DIRECTOR: 'ROLE_MULTI_CENTER_DIRECTOR', // Same as ROLE_DIRECTOR but for multi centers
};

export const ALL_ROLES = Object.values(ROLES);

const superAdminPermissions = [
  SCOPES.canLogInAsAdmin,
  SCOPES.canViewMyAccount,
  SCOPES.canViewMyAccountAsAdmin,
  SCOPES.canViewAdminDashboard,
  SCOPES.canViewSuppliers,
  SCOPES.canViewSuppliersMargins,
  SCOPES.canViewSuppliersDiscounts,
  SCOPES.canViewClients,
  SCOPES.canImpersonateUser,
  SCOPES.canViewClientAccountComment,
  SCOPES.canViewKeyAccountClients,
  SCOPES.canUpdateClientStatus,
  SCOPES.canViewAdminOrders,
  SCOPES.canUpdateOrderStatus,
  SCOPES.canViewProductBuyingPrice,
  SCOPES.canCreateKeyAccount,
  SCOPES.canViewAlerts,
  SCOPES.canViewNationwideOperations,
  SCOPES.canRequestTrackingAndPOD,
  SCOPES.canAskForRefund,
  SCOPES.canEditCompanyInfo,
  SCOPES.canEditAccountInfo,
  SCOPES.canCreateNationwideOperations,
  SCOPES.canEditNationwideOperations,
  SCOPES.canLockOrUnlockParticipation,
  SCOPES.canEditCenterStatus,
];

const directorPermissions = [
  SCOPES.canViewClientHome,
  SCOPES.canViewProductCatalog,
  SCOPES.canAccessCart,
  SCOPES.canAddToCart,
  SCOPES.canViewFooter,

  // account
  SCOPES.canViewMyAccount,
  SCOPES.canEditAccountInfo,
  SCOPES.canViewCompanyInfo,
  SCOPES.canEditCompanyInfo,
  SCOPES.canViewPaymentMethods,
  SCOPES.canViewPricesManagment,
  SCOPES.canViewAddresses,
  SCOPES.canViewClientUsers,

  // orders
  SCOPES.canViewClientOrders,

  // nationwide operations
  SCOPES.canViewClientNationwideOperations,
];

export const PERMISSIONS = {
  // administrators
  [ROLES.ROLE_TECHNICAL_SUPER_ADMIN]: superAdminPermissions,
  [ROLES.ROLE_SUPER_ADMIN]: superAdminPermissions,

  [ROLES.ROLE_ADMIN]: [
    SCOPES.canLogInAsAdmin,
    SCOPES.canViewAdminDashboard,
    SCOPES.canViewSuppliers,
    SCOPES.canViewClients,
    SCOPES.canViewKeyAccountClients,
    SCOPES.canViewAdminOrders,
    SCOPES.canUpdateOrderStatus,
    SCOPES.canUpdateClientStatus,
    SCOPES.canViewProductBuyingPrice,
    SCOPES.canViewMyAccount,
    SCOPES.canViewMyAccountAsAdmin,
    SCOPES.canEditAccountInfo,
    SCOPES.canViewNationwideOperations,
    SCOPES.canViewClientAccountComment,
    SCOPES.canEditCompanyInfo,
  ],

  [ROLES.ROLE_KEY_ACCOUNT_CLIENT_ADMIN]: [
    SCOPES.canLogInAsAdmin,
    SCOPES.canViewMyAccount,
    SCOPES.canViewMyAccountAsAdmin,
    SCOPES.canViewKeyAccountAdminDashboard,
    SCOPES.canViewKeyAccountAdminClients,
    SCOPES.canViewAdminOrders,
    SCOPES.canViewClients,
    SCOPES.canViewKeyAccountAdminLabel,
    SCOPES.canViewKeyAccountAdminMenu,
    SCOPES.canViewKeyAccountAdminOrders,
    SCOPES.canViewKeyAccountAdminOrderDetails,
    SCOPES.canViewNationwideOperations,
  ],

  [ROLES.ROLE_KEY_ACCOUNT_CLIENT_REGIONAL_ADMIN]: [
    SCOPES.canLogInAsAdmin,
    SCOPES.canViewMyAccount,
    SCOPES.canViewMyAccountAsAdmin,
    SCOPES.canViewKeyAccountAdminDashboard,
    SCOPES.canViewKeyAccountAdminClients,
    SCOPES.canViewAdminOrders,
    SCOPES.canViewClients,
    SCOPES.canImpersonateUser,
    SCOPES.canViewKeyAccountRegionalAdminLabel,
    SCOPES.canViewKeyAccountAdminMenu,
    SCOPES.canViewKeyAccountAdminOrders,
    SCOPES.canViewKeyAccountAdminOrderDetails,
    SCOPES.canViewNationwideOperations,
  ],

  // clients
  [ROLES.ROLE_USER]: [
    SCOPES.canViewClientHome,
    SCOPES.canViewProductCatalog,
    SCOPES.canViewFooter,
    SCOPES.canViewMyAccount,
  ],

  [ROLES.ROLE_CUSTOMER]: [
    SCOPES.canViewClientHome,
    SCOPES.canViewProductCatalog,
    SCOPES.canAccessCart,
    SCOPES.canAddToCart,
    SCOPES.canViewFooter,

    // account
    SCOPES.canViewMyAccount,

    // orders
    SCOPES.canViewClientOrders,
  ],

  [ROLES.ROLE_MAIN_USER]: directorPermissions,
  [ROLES.ROLE_DIRECTOR]: directorPermissions,

  [ROLES.ROLE_MULTI_CENTER_DIRECTOR]: [
    SCOPES.canViewClientDashboard,
    SCOPES.canViewProductCatalog,
    SCOPES.canAccessCart,
    SCOPES.canAddToCart,
    SCOPES.canViewFooter,

    // account
    SCOPES.canEditAccountInfo,
    SCOPES.canViewCompanyInfo,
    SCOPES.canEditCompanyInfo,
    SCOPES.canViewPaymentMethods,
    SCOPES.canViewPricesManagment,
    SCOPES.canViewAddresses,
    SCOPES.canViewClientUsers,

    // orders
    SCOPES.canViewClientOrders,
    SCOPES.canViewMultiCenterOrders,

    // centers
    SCOPES.canViewClients,
    SCOPES.canViewCenters,

    // nationwide operations
    SCOPES.canViewClientNationwideOperations,
    SCOPES.canValidateParticipation,
  ],
};
